<template>

<app-page>

	<app-page-head title="Share">

		<app-page-head-action :loading="is.saving" icon="share" tooltip="Generate URL" v-on:click.native="onSaveClick" />

	</app-page-head>

	<app-page-content :is-padded="true" class="share">

		<p>You can share a preview of the guide to third parties without needing to publish changes or provide access to the manager.</p>

		<p>Simply configure the type of preview to share and click the share button to generate a URL.</p>

		<app-input-toggle v-model="model.type" label="Layout" :disabled="is.saving" :options="$constants.previewTypes" />
		<app-input-text v-model="model.bugherd" label="Bugherd Key" :disabled="is.saving" notes="Enable Bugherd integration by adding a project key" />

		<div class="share-code" v-tooltip="'Click to copy'" v-on:click="onCodeClick" v-if="code">

			<div class="share-code-copy" v-if="!is.copied">click to copy</div>
			<div class="share-code-copy is-copied" v-if="is.copied">copied!</div>

			<div v-html="code" />

		</div>

	</app-page-content>

</app-page>

</template>

<script>

import copy from 'copy-to-clipboard'

export default {
	
	data: function() {

		return {
			is: {
				copied: false,
				saving: false
			},
			model: {
				type: 1,
				bugherd: ''
			},
			code: ''
		}

	},

	methods: {

		onSaveClick: function() {

			this.is.saving = true
			
			this.$api.post('preview', this.model).then(function(json) {

				this.is.saving = false
				this.is.copied = false

				this.code = json.url

			}.bind(this))

		},

		onCodeClick: function() {

			copy(this.code)

			this.is.copied = true

		}

	}

}

</script>

<style scoped>

.share p {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}

.share-code {
	background-color: #eee;
	padding: 30px 20px;
	font-family: 'Courier New', Courier, monospace;
	font-size: 12px;
	line-height: 20px;
	border-left: 10px solid #999;
	cursor: pointer;
}

.share-code-copy {
	font-size: 10px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 4px;
	position: absolute;
	right: 4px;
	top: 4px;
	padding: 0px 10px;
}

.share-code-copy.is-copied {
	background-color: #4082d3;
}

</style>
